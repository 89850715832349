/*
  Project: Obsideo Commercial
  Author: cre8ive
 */

/*Sticky Header*/
$(function () {
    $(window).on("scroll", function () {
        if ($(window).scrollTop() > 400) {
            $(".oc-header").addClass("header-sticky");
        } else {
            //remove the background property so it comes transparent again (defined in your css)
            $(".oc-header").removeClass("header-sticky");
        }
    });
});
/*Sticky Header*/
/* Mobile Menu */
$('.oc-header li.menu-item-has-children').on('click', function(){
    if( $(this).hasClass('submenu-open') ) {
        $(this).removeClass('submenu-open');
        $(this).find('.c-main-nav__dropdown').removeClass('open');
    } else {
        $('.oc-header li.menu-item-has-children').removeClass('submenu-open');
        $('.oc-header li.menu-item-has-children').find('.c-main-nav__dropdown').removeClass('open');

        $(this).addClass('submenu-open');
        $(this).find('.c-main-nav__dropdown').addClass('open');
    }
});
/* Mobile Menu */


$('#navbar-toggle').click(function(){
    $('.navbar').slideToggle();
});

if($('#cust-upload').length) {
    $('#cust-upload').change(function(e){
        var fileName = e.target.files[0].name;
        $('.up-file-title').html(fileName);
    });
}
